.RBWidget-module_rb-widget_rhTfQ__Main {
  background: var(--bg-block);
  border-radius: 10px;
}

.RBWidget-module_rb-widget__header_Ti5E6__Main {
  height: 40px;
  margin-bottom: 10px;
}

.RBWidget-module_rb-widget__footer_RNpOs__Main {
  height: 60px;
  padding: 10px;
}

.RBWidget-module_rb-widget__main-title_60-9K__Main {
  color: var(--text-primary);
  padding: 10px 10px 0;
}

.RBWidget-module_rb-widget__list_Utrez__Main {
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
  display: flex;
  position: relative;
}

.RBWidget-module_rb-widget__list_Utrez__Main:after {
  content: "";
  background-color: var(--line-line);
  width: calc(100% - 20px);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 10px;
}

.RBWidget-module_rb-widget__line_YPvVF__Main {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.RBWidget-module_rb-widget__line__logo-wrapper_qgktb__Main {
  min-width: 60px;
  max-width: 60px;
  height: 20px;
}

.RBWidget-module_rb-widget__line__logo-img-wrapper_UT9e-__Main img {
  object-position: center;
}

.RBWidget-module_rb-widget__bonus_LEIB4__Main {
  border: 1px solid var(--line-stroke);
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  width: calc(100% - 30px);
  height: auto;
  padding: 10px 30px 10px 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.RBWidget-module_rb-widget__bonus-text_fTgQK__Main {
  color: var(--text-primary);
}

.RBWidget-module_rb-widget__bonus-icon_RsOJn__Main {
  fill: var(--icons-primary);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.RBWidget-module_rb-widget__bonus--partner_-6Tlb__Main {
  border: 2px solid var(--icons-yellow);
}

.RBWidget-module_rb-widget__bonus--partner-icon_nojTh__Main {
  fill: var(--icons-yellow);
}

.RBWidget-module_rb-widget__review-icon_FGE5S__Main {
  fill: var(--icons-primary);
  cursor: pointer;
  padding: 2px;
}

